import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Layout from './pages/layout';
import Home from './pages/home';
import Contact from './pages/contact';
import PrivacyPolicy from './pages/privacy-policy';
import OurRetreatPlans from './pages/our_retreat_plans';
import NoPage from './pages/404';
import AboutUs from './pages/about';
import BlogById from './pages/blogs/blog_by_id';
import ProfilePage from './pages/profile';
import { useAuthContext } from './contexts/AuthContextProvider';
import { useEffect, useState } from 'react';
import SignIn from './pages/login';
import SignUp from './pages/signup';
import AllRetreats from './pages/our_retreat_plans/all_retreats';
import AllBlogs from './pages/blogs/all_blogs';
import { useNavigate, Navigate } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner'
import Financing from './pages/our_retreat_plans/financing';
import Accommodation from './pages/home/accommodation';


function App() {
  const { checkUser, isLoggedIn } = useAuthContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const fetchCheckUser = async () => {
    setLoading(true)
    await checkUser();
    setLoading(false)

  }

  useEffect(() => {
    fetchCheckUser();
  }, []);


  return (
    <div className="font-visby">
      {
        !loading ?
          <Routes>

            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="login" element={<SignIn />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="contact-us" element={<Contact />} />
              <Route path="about-us" element={<AboutUs />} />
              <Route path="retreat-plans" element={<AllRetreats />} />
              <Route path="retreat-plans/:id" element={<OurRetreatPlans />} />
              <Route path="blog/:id" element={<BlogById />} />
              <Route path="blogs" element={<AllBlogs />} />
              <Route path="profile" element={<ProfilePage />} />
              <Route path="financing" element={<Financing />} />
              {/* <Route path="accommodation" element={<Accommodation />} /> */}
            </Route>

            <Route path="*" element={<NoPage />} />
          </Routes> :
          <div className="flex items-center justify-center w-full my-20 text-center"><ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["black"]}
          /></div>
      }
    </div>
  );
}

export default App;
