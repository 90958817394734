import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from 'yup';
import { useFinanceContext } from '../../contexts/FinanceContextProvider';
import { jsonHeader, multiFormHeader } from '../../apis/header';
import ApiTemplate from '../../apis/api_template';


const Financing = () => {

  const {postFinanceList} = useFinanceContext();

  const validationSchema = Yup.object({
    fullname: Yup.string().required('Name is required'),
    email:Yup.string().email('Invalid Email').required("Email is required"),
    profilePicture: Yup.array().min(1, 'At least one file must be uploaded'),
    selectGender: Yup.string().required('select one gender'),
    dob: Yup.string().required('Add your DOB'),
    employment: Yup.string().required('Select You Employment status'),
    age: Yup.string().required('Select age'),
    phoneNumber: Yup.string().required('Add phone number'),
    country: Yup.string().required('Select your country'),
    profession: Yup.string().required('Select your Profession'),
    annualIncome: Yup.number().required('Select your annual gross income'),
    academicDegrees: Yup.array()
      .min(1, 'At least one academic degree must be selected'),
    package: Yup.string().required('Add the link of the package that you want Finance for'),
    multipleFiles: Yup.array().min(1, 'At least one file must be uploaded').default([]),

  });

  const options = [
    { value: "0-25000", label: "$0 - $25,000" },
    { value: "25001-50000", label: "$25,001 - $50,000" },
    { value: "50001-75000", label: "$50,001 - $75,000" },
    { value: "75001-100000", label: "$75,001 - $100,000" },
    { value: "150001+", label: "$150,001+" },
  ];

  // Generate unique ID
const generateUniqueId = () => {
  const randomNum = Math.floor(Math.random() * 100000); 
  return `user-${randomNum}`;
};
  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("Valuessss :", values)
    try {
      const formData = new FormData();
      const uniqueId = generateUniqueId();
      
      // Append each form field to FormData
      formData.append("full_name", values.fullname);
      formData.append("gender", values.selectGender);
      formData.append("email", values.email)
      formData.append("age", values.age);
      formData.append("dob", new Date(values.dob).toISOString().split("T")[0],);
      formData.append("emp_status", values.employment);
      formData.append("phone", values.phoneNumber);
      formData.append("country", values.country);
      formData.append("profession", values.profession);
      formData.append("a_gross_income", values.annualIncome);
      formData.append("degree", values.academicDegrees[0]);
      formData.append("social_media_link", values.socialLink);
      formData.append("pkg_link", values.package);
      formData.append("u_id", uniqueId);
      // formData.append("upload_files", values.multipleFiles)
  // Append single file for profile picture
if (values.profilePicture && values.profilePicture[0] instanceof File) {
  formData.append("profile_picture", values.profilePicture);
}

// Append multiple files for upload_files array
if (Array.isArray(values.multipleFiles)) {
  values.multipleFiles.forEach((file, index) => {
    if (file instanceof File) {
      formData.append("upload_files[]", file); // Adjust name if backend expects different format
    } else {
      console.warn("Invalid file:", file);
    }
  });
}



      const headers = {
        // Do not include Content-Type header here, as FormData automatically sets it
        ...multiFormHeader(),
      };
  
      const response = await ApiTemplate("post", "/api/v1/finance/store", formData, headers);
  
      if (response && response["success"] === true) {
        console.log(response["data"]);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  
    setSubmitting(false);
  };
  

  return (
    <div className='financing'>
      <section>
        <div className='financing-form w-[95%] md:w-[78%] lg:w-[78%]  mx-auto'>
          {/* Header */}
          <div className='p-7 bg-customGreen lg:w-[100%]'>
          </div>
          {/* Body */}
          <div className='p-5 lg:w-[100%]'>
            <h1 className='text-3xl font-bold mx-auto flex justify-center'>Application for Finance  </h1>
          </div>
          <div className='lg:w-[94%] mx-auto'>
            <Formik
              initialValues={{
                fullname: '',
                email:'',
                profilePicture: null,
                selectGender: '',
                // gender: {},
                dob: '',
                employment: '',
                age: '',
                phoneNumber: '',
                country: '',
                profession: '',
                annualIncome: null,
                academicDegrees: [],
                socialLink:'',
                package: '',
                multipleFiles: [],
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                setFieldValue,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  {/* Full Name */}
                  <div className="mb-5">
                    <label htmlFor="fullname" className="block mb-2 text-sm">Full Name</label>
                    <input type="text" id="fullname"
                      name='fullname'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fullname}
                      placeholder="Full Name"
                      className=" border border-black  text-gray-900 text-sm rounded-lg  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " />
                    <p style={{ color: "red" }}>{errors.fullname && touched.fullname && errors.fullname}</p>
                  </div>

                  {/* Email */}
                  <div className="mb-5">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                    <input type="email" id="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder='Enter your email'
                      className=" border border-black text-gray-900 text-sm rounded-lg  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " />

                    <p style={{ color: "red" }}>
                      {errors.email && touched.email && errors.email}

                    </p>
                  </div>

                  {/* File Upload */}
                  <div className='mb-5'>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">Profile Picture</label>
                    <input
                      name='profilePicture'
                      onChange={(event) => setFieldValue('profilePicture', Array.from(event.currentTarget.files))}
                      className="block w-full text-sm text-gray-900 border border-black rounded-lg cursor-pointer p-1 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file" />
                    <p style={{ color: "red" }}>{errors.profilePicture && touched.profilePicture && errors.profilePicture}</p>

                  </div>

                  {/* Gender */}
                  <div className="block mb-5">
                    <label htmlFor="selectGender" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Gender
                    </label>

                    {/* Using Formik's Field component for handling form state */}
                    <Field as="select" name="selectGender" id="selectGender"
                      className="  border border-black text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                      {/* Placeholder option with empty value */}
                      <option value="">Select gender</option>
                      <option value="Mr." className={values.selectGender === 'Mr.' ? "bg-customGreen":""}>Mr.</option>
                      <option value="Miss">Miss</option>
                      <option value="Ms.">Ms.</option>
                      <option value="Mrs.">Mrs.</option>
                    </Field>

                    {/* Error message */}
                    <p style={{ color: "red" }}>
                      {errors.selectGender && touched.selectGender && errors.selectGender}
                    </p>
                  </div>

                   {/* Age */}
                   <div className="block mb-5">
                    <label htmlFor="age" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Age
                    </label>

                    {/* Using Formik's Field component for handling form state */}
                    <Field as="select" name="age" id="age"
                      className=" border border-black text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                      {/* Placeholder option with empty value */}
                      <option value="">Select your age</option>
                      <option value="21 to 30">21 to 30</option>
                      <option value="31 to 50">21 to 30</option>
                      <option value="more than 50">21 to 30</option>
                    </Field>

                    {/* Error message */}
                    <p style={{ color: "red" }}>
                      {errors.age && touched.age && errors.age}
                    </p>
                  </div>
               

                  {/* DOB */}

                  <div className=" mb-5 dob-picker">
                    <label htmlFor="date" className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>DOB</label>

                    {/* Using DatePicker for DOB */}
                    <DatePicker
                      className='border border-black text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      selected={values.dob}
                      dateFormat="yyyy/MM/dd"
                      placeholderText="Select DOB"
                      onChange={(val) => {
                        setFieldValue('dob', val);
                        console.log(val)
                      }}
                      showIcon
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                    <p style={{ color: "red" }}>{errors.dob && touched.dob && errors.dob}</p>

                  </div>

                  {/* Employment Status */}
                  <div className="block mb-5">
                    <label htmlFor="employment" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Employment Status
                    </label>

                    {/* Using Formik's Field component for handling form state */}
                    <Field as="select" name="employment" id="employment"
                      className=" border border-black text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                      {/* Placeholder option with empty value */}
                      <option value="">Employement Status</option>
                      <option value="fulltime">Full time</option>
                      <option value="parttime">Part time</option>
                      <option value="selfemployed">Self Employed</option>
                    </Field>

                    {/* Error message */}
                    <p style={{ color: "red" }}>
                      {errors.employment && touched.employment && errors.employment}
                    </p>
                  </div>

                  {/* Phone number */}
                  <div className="mb-5">
                    <label htmlFor="phoneNumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
                    <input type="text" id="phoneNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber}
                      placeholder='Enter your number'
                      className=" border border-black text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                    {/* Error message */}
                    <p style={{ color: "red" }}>
                      {errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}

                    </p>
                  </div>

                  {/* Country */}
                  <div className="block mb-5">
                    <label htmlFor="country" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Country
                    </label>

                    {/* Using Formik's Field component for handling form state */}
                    <Field as="select" name="country" id="country"
                      className=" border border-black text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                      {/* Placeholder option with empty value */}
                      <option value="">Choose a country</option>
                      <option value="US">United States</option>
                      <option value="CA">Canada</option>
                      <option value="FR">France</option>
                      <option value="DE">Germany</option>
                    </Field>

                    {/* Error message */}
                    <p style={{ color: "red" }}>
                      {errors.country && touched.country && errors.country}
                    </p>
                  </div>

                  {/* select your profession*/}
                  <div className="block mb-5">
                    <label htmlFor="country" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Select your profession 
                    </label>

                    {/* Using Formik's Field component for handling form state */}
                    <Field as="select" name="profession" id="profession"
                      className=" border border-black text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                      {/* Placeholder option with empty value */}
                      <option value="">Choose Profession</option>
                      <option value="US">Engineer</option>
                      <option value="CA">Doctor</option>
                      <option value="FR">Designer</option>
                      <option value="DE">Developer</option>
                      <option value="DE">Other</option>
                    </Field>

                    {/* Error message */}
                    <p style={{ color: "red" }}>
                      {errors.profession && touched.profession && errors.profession}
                    </p>
                  </div>

                  {/* Annual gross Income */}
                  <div className="block mb-5">
                    <label htmlFor="annualIncome" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Annual gross income
                    </label>

                    {/* Using Formik's Field component for handling form state */}
                    <Field as="select" name="annualIncome" id="annualIncome"
                      className=" border border-black text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                      {/* Placeholder option with empty value */}
                      <option value="">Select annual gross income</option>
                      <option value={25000}>$0 - $25,000</option>
                      <option value={50000}>$25,001 - $50,000</option>
                      <option value={75000}>$50,001 - $75,000</option>
                      <option value={100000}>$75,001 - $100,000</option>
                      <option value={150001}>$150,001+</option>
                    </Field>

                    {/* Error message */}
                    <p style={{ color: "red" }}>
                      {errors.annualIncome && touched.annualIncome && errors.annualIncome}
                    </p>
                  </div>

                  {/* Academic Degrees */}
                  <fieldset className="block mb-5">
                    <legend className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-2">Academic Degrees</legend>

                    {['Bachelor’s', 'Master’s', 'PhD', 'Vocational', 'Training Courses', 'Diploma'].map((degree, index) => (
                      <div key={index} className="mb-2 border border-black rounded-lg  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ">
                        <input
                          id={degree}
                          name="academicDegrees"
                          type="checkbox"
                          value={degree}
                          onChange={handleChange}
                          checked={values.academicDegrees.includes(degree)}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded  dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label htmlFor='degree' className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                          {degree}
                        </label>
                      </div>
                    ))}

                    <p style={{ color: 'red' }}>{errors.academicDegrees && touched.academicDegrees && errors.academicDegrees}</p>
                  </fieldset>

                  {/* Social Media Link */}

                  <div className="mb-5">
                    <label htmlFor="socialLink" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Paste the link of your social media like : Facebook, LinkedIn, Instagram, Youtube Channel “Optional”</label>
                    <input type="text" id="socialLink"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.socialLink}
                      className=" border border-black text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                    {/* {errors.email && touched.email && errors.email} */}
                  </div>

                  {/* package Link */}

                  <div className="mb-5">
                    <label htmlFor="package " className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Link of the package that you want Finance for </label>
                    <input type="text" id="package"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.package}
                      className=" border border-black text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                    <p style={{ color: 'red' }}>{errors.package && touched.package && errors.package}</p>
                  </div>


                  {/* File Upload */}
                  <div className='mb-5'>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="multipleFiles">Upload the following files: A copy of your identity card or registration certificate, a copy of your criminal record no later than a month after issuance, and a CV</label>
                    <input

                      name="multipleFiles"
                      className="block w-full text-sm text-gray-900 border border-black rounded-lg p-1 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      id="multipleFiles" type="file"
                      multiple
                      onChange={(event) => {
                        const filesArray = Array.from(event.target.files);
                        setFieldValue("multipleFiles", [...values.multipleFiles, ...filesArray]);
                        console.log("multipleFiles as array:", filesArray);
                        // setFieldValue("multipleFiles", Array.from(event.target.files))
                        
                      }}
                    />
                    {/* Error handling */}
                    {errors.multipleFiles && touched.multipleFiles && (
                      <p style={{ color: 'red' }}>{errors.multipleFiles}</p>
                    )}
                  </div>

                  <button type="submit" disabled={isSubmitting} className='bg-customGreen w-[100%] p-4 text-2xl rounded'>
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Financing