import React, { useState } from "react";
import GoogleLogo from '../../images/google_logo.svg';
import { Link } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContextProvider";
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle } from '../../firebase';

const SignUp = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        mobileNumber: "",
        address: "",
        password: "",
        confirmPassword: "",
        agreeTerms: false,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const { signup,signupwithgooogle } = useAuthContext();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCheckboxChange = () => {
        setFormData({
            ...formData,
            agreeTerms: !formData.agreeTerms,
        });
    };

    const handleSignUp = async (e) => {
        try {
            e.preventDefault();
            setError(""); // Resetting previous errors
            setIsLoading(true);

            const requiredFields = ['fullName', 'email', 'mobileNumber', 'address', 'password', 'confirmPassword'];
            const emptyFields = requiredFields.filter(field => !formData[field]);

            if (emptyFields.length > 0) {
                setError(`Please fill in all required fields: ${emptyFields.join(', ')}`);
                setIsLoading(false);
                return;
            }

            if (formData.password !== formData.confirmPassword) {
                setError("Passwords do not match.");
                setIsLoading(false);
                return;
            }
            if (formData.agreeTerms == false) {
                setError("Please check terms and conditions.");
                setIsLoading(false);
                return;
            }
            const response = await signup({
                name: formData.fullName,
                phone: formData.mobileNumber,
                address: formData.address,
                email: formData.email,
                password: formData.password,
            });
            console.log(response)
            if (response && response["success"] == false) {
                setError(response["error"].message + ": " + response["error"].data[0]);
                setIsLoading(false);
                return;
            }
            if (!response) {
                setError("Failed to sign up. Please try again.");
                setIsLoading(false);
                return;
            }
            setIsLoading(false);
            navigate('/');

            // Possibly close the modal or redirect upon successful login
        } catch (error) {
            setError("Failed to sign up. Please try again."); // Update error state
            setIsLoading(false);
        }
    };

    const handleSignUpWithGoogle = async (e) => {
        try {
            e.preventDefault();
            setError(""); // Resetting previous errors
            // setIsLoading(true);

            const googleResponse = await signInWithGoogle();
            console.log(googleResponse)
            const response = await signupwithgooogle(JSON.stringify({
                "auth_type":"google",
                name: googleResponse.user.displayName ? googleResponse.user.displayName: "",
                phone: googleResponse.user.phoneNumber ? googleResponse.user.phoneNumber: "",
                address: googleResponse.user.photoURL?googleResponse.user.photoURL: "",
                email: googleResponse.user.email ? googleResponse.user.email: "",
                uid: googleResponse.user.uid ? googleResponse.user.uid: "",
                avater: googleResponse.user.photoURL ? googleResponse.user.photoURL: "",
            }));
            console.log(response)
            if (response && response["success"] == false) {
                setError(response["error"].message + ": " + response["error"].data[0]);
                setIsLoading(false);
                return;
            }
            if (!response) {
                setError("Failed to sign up. Please try again.");
                setIsLoading(false);
                return;
            }
            setIsLoading(false);
            navigate('/');

            // Possibly close the modal or redirect upon successful login
        } catch (error) {
            setError("Failed to sign up. Please try again."); // Update error state
            setIsLoading(false);
        }
    }


    return (
        <section className="w-full py-10">
            <div className="text-black flex flex-col md:w-[23rem] mx-auto">
                <h1 className="mb-3 text-2xl font-black">Sign Up</h1>
                <h3 className="text-xl text-customGreen">Welcome!</h3>
                <p className="mb-3 text-sm text-customGray">
                    Please sign up to enjoy our best retreat plans.
                </p>
                <div>
                    <div className="mb-3">
                        <input
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleInputChange}
                            placeholder="Full Name"
                            className="w-full px-3 py-2 border rounded-md"
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder="Email"
                            className="w-full px-3 py-2 border rounded-md"
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="tel"
                            name="mobileNumber"
                            value={formData.mobileNumber}
                            onChange={handleInputChange}
                            placeholder="Mobile Number"
                            className="w-full px-3 py-2 border rounded-md"
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                            placeholder="Address"
                            className="w-full px-3 py-2 border rounded-md"
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            placeholder="Password"
                            className="w-full px-3 py-2 border rounded-md"
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleInputChange}
                            placeholder="Confirm Password"
                            className="w-full px-3 py-2 border rounded-md"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="flex items-center text-xs">
                            <input name="agreeTerms" checked={formData.agreeTerms}
                                onChange={handleCheckboxChange} type="checkbox" className="mr-2" /><p>I agree with all your
                                <Link to={"/privacy-policy"}><span className="text-customGreen">{" "} Terms & Conditions {" "}</span></Link> of
                                clarity</p>
                        </label>
                    </div>
                    {error && <p className="text-red-500 text-sm pt-3">{error}</p>}
                    <div className="flex flex-col w-full gap-3 md:mt-0-5">
                        <button
                            onClick={handleSignUp}
                            className="bg-[#8DC67B] text-white text-sm py-2 px-4 rounded-md mr-2 w-full"
                        >
                            {isLoading ? 'Signing Up...' : 'Sign Up'}
                        </button>
                        <button onClick={handleSignUpWithGoogle} className="flex items-center justify-center w-full gap-3 px-4 py-2 text-sm text-black rounded-md bg-customGray bg-opacity-30">
                            <img loading="lazy" src={GoogleLogo} alt="Google" className="w-5"></img> <p>Sign Up With Google</p>
                        </button>
                    </div>
                </div>
                <p className="mt-4 text-xs text-center text-customGray">
                    If you already have an account!{" "}
                    <Link to={"/login"}>
                        <span className="text-[#8DC67B] cursor-pointer">
                            Sign In
                        </span>
                    </Link>
                </p>
            </div>
        </section>
    );
};

export default SignUp;
