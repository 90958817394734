import React, { useState, useEffect, useRef } from "react";
import { FiSearch, FiChevronUp, FiChevronDown } from "react-icons/fi";
import ClickAwayListener from "react-click-away-listener";
import { useLocationContext } from "../contexts/LocationContextProvider";
import { useEventContext } from "../contexts/EventContextProvider";
import filterIcon from "../images/Filter1.png";
import { Link, NavLink } from "react-router-dom";

// import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { useQuestionsContext } from "../contexts/QuestionsContextProvider";


const SearchableDropdown = () => {
  const [query, setQuery] = useState("");
  const dropdownButtonRef = useRef(null);
  const [options, setOptions] = useState(["All"]);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const { allLocations,
    getLocations,
  } = useLocationContext();
  const { getEventsByLocation, getEvents } = useEventContext();
  const dropdownRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen]=useState(false);
  const {allQuestions , getQuestionsSuccess , getQuestions} = useQuestionsContext();
  const modalRef = useRef(null);



  const updateOptions = () => {
    options && setFilteredOptions(options);
  }

  const getAllLocations = async () => {
    await getLocations();
  }

  const setLocationsOptions = async () => {
    allLocations && setOptions(["All", ...allLocations.map(item => item.name)])
  }
  const handleOpenModal =async () => {
    setIsModalOpen(true);
    await getQuestions();
  };
  const handleCloseModal = () => setIsModalOpen(false);

  useEffect(()=>{
    getQuestions();
  },[isModalOpen])

  useEffect(() => {
    getAllLocations();
  }, [])

  useEffect(() => {
    setLocationsOptions();
  }, [allLocations])

  useEffect(() => {
    updateOptions();
  }, [options])

    // Close modal if clicked outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          handleCloseModal();
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);


  const handleInputChange = (e) => {
    setQuery(e.target.value);
    if (allLocations) {
      setFilteredOptions([
        ...options.filter((option) =>
          option.toLowerCase().includes(e.target.value.toLowerCase()),

        )],


      );

    }
    setIsDropdownVisible(e.target.value.length > 0);
  };

  const toggleDropdown = () => {
    setIsDropdownVisible(isDropdownVisible => !isDropdownVisible);
  };

  const handleDropdownClickAway = () => {
    setIsDropdownVisible(false);
  };

  const handleGo = async () => {
    console.log("button go")
    setLoading(true)
    if (query !== "All") {
      const data = JSON.stringify({ "location": query })
      await getEventsByLocation(data);
    } else {
      await getEvents();
    }
    setLoading(false)
  }

  const handleOptionClick = (option) => {
    setQuery(option);
    setLocationsOptions();
    setIsDropdownVisible(false);
  }

  const [openDropdown, setOpenDropdown] = useState(null);

  const validationSchema = Yup.object(
    (allQuestions || []).reduce((acc, question) => {
      acc[`selectedAnswers_${question.id}`] = Yup.string()
        .required(`Please select an answer for "${question.question}"`);
      return acc;
    }, {})
  );
  const toggleDropdownFaq = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };


  return (
    <div className="z-10 mt-5 flex md:w-full gap-5">
      
      <div className="relative inline-block text-left w-[80%]" ref={dropdownRef}>
        <div className="relative">
          <input
            type="text"
            className="block text-black outline-none border-none w-full pl-3 pr-10 py-3 text-base border border-gray-300 rounded-md focus:ring focus:ring-indigo-300 focus:border-indigo-300"
            placeholder="Search"
            // onMouseDownCapture={()=>setIsDropdownVisible(true)}
            value={query}
            onChange={handleInputChange}
          />
          <div
            className="absolute inset-y-0 right-0 flex items-center px-3 cursor-pointer"
            onClick={toggleDropdown}
            ref={dropdownButtonRef}
          >
            {isDropdownVisible ? (
              <FiChevronUp className="text-black" />
            ) : (
              <FiChevronDown className="text-black" />
            )}
          </div>
        </div>

        {isDropdownVisible && filteredOptions.length > 0 ? (
          <ClickAwayListener onClickAway={handleDropdownClickAway}><div className="mt-2 max-h-[10rem] overflow-y-scroll w-full absolute z-10 bg-white border border-gray-300 rounded-md shadow-lg">
            {filteredOptions.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(option)}
                className={`py-2 px-4 text-black cursor-pointer hover:bg-gray-100 ${option === query ? "bg-green-300 font-bold" : ""}`}
              >
                {option}
              </div>
            ))}
          </div>
          </ClickAwayListener>
        ) :
          isDropdownVisible && <ClickAwayListener onClickAway={handleDropdownClickAway}><div className="mt-2 max-h-[10rem] overflow-y-scroll w-full absolute z-10 bg-white border border-gray-300 rounded-md shadow-lg">
            <div

              className="py-2 px-4 text-black cursor-pointer hover:bg-gray-100"
            >
              No Location Found.
            </div>
          </div>
          </ClickAwayListener>

        }
      </div>
      
      <button onClick={handleGo} className="bg-[#8DC67B] text-black rounded-md w-[20%] z-10">{loading ? "Loading..." : "Go!"}</button>
      {/* <Link to={'/accommodation'} className="cursor-pointer z-10"> */}
      <img loading="lazy" src={filterIcon} alt="Profile" className="w-10 mt-1 z-10 cursor-pointer" onClick={handleOpenModal}/>
      {/* </Link> */}

{/* Faqs modal */}
    { isModalOpen &&
    
   

    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
  <div ref={modalRef} className="accomadation bg-white h-[80%] w-[97%] md:w-[78%] lg:w-[50%] lg:mt-10 p-6 mx-auto shadow-lg overflow-y-scroll rounded-lg">
  <Formik
  initialValues={(allQuestions || []).reduce((acc, question) => {
    acc[`selectedAnswers_${question.id}`] = "";
    return acc;
  }, {})}
  validationSchema={validationSchema}
  onSubmit={(values, { setSubmitting }) => {
    const results = (allQuestions || []).map((question) => ({
      question: question.question,
      selectedAnswer: values[`selectedAnswers_${question.id}`],
    }));

    console.log("Submitted Answers with Questions:", results);

    setSubmitting(false); // Ensure form state is reset after submission
    handleCloseModal();
  }}
>
  {({ values, setFieldValue, isSubmitting, validateForm }) => (
    <Form>
      <div className="faq container mx-auto p-4 space-y-6">
        {/* Form Title and Loading Status */}
        <div className="py-5 lg:w-full">
          <h1 className="text-3xl font-bold mx-auto text-black">
            <span className="font-bold">.</span> Accommodation
          </h1>
        </div>
        {!getQuestionsSuccess ? (
          <h1 className="text-black">Loading...</h1>
        ) : (
          allQuestions?.map((vals) => (
            <div key={vals.id} className="relative mb-6">
              <div className="question font-semibold text-lg text-black">
                <p>{vals.question}</p>
              </div>

              {/* Custom Dropdown */}
              <div
                className="custom-dropdown relative border border-slate-500 rounded-lg p-3 mt-2 cursor-pointer w-full flex justify-between items-center"
                onClick={() => toggleDropdownFaq(vals.id)}
              >
                <p className="m-0 text-black">
                  {values[`selectedAnswers_${vals.id}`] || "Select an answer"}
                </p>
                <div className="absolute top-3 right-3">
                  {openDropdown === vals.id ? (
                    <IoIosArrowDown className="font-bold text-black" size={22} />
                  ) : (
                    <IoIosArrowUp className="font-bold text-black" size={22} />
                  )}
                </div>
              </div>

              {/* Dropdown Options */}
              {openDropdown === vals.id && (
                <div className="absolute z-10 bg-white rounded-lg w-full mt-1 shadow-md">
                  {vals.answers.map((answer) => (
                    <div
                      key={answer}
                      onClick={() => {
                        setFieldValue(`selectedAnswers_${vals.id}`, answer);
                        toggleDropdownFaq(vals.id);
                      }}
                      className={`p-3 cursor-pointer rounded-md mb-1 ${
                        values[`selectedAnswers_${vals.id}`] === answer
                          ? "bg-customGreen text-white"
                          : "bg-gray-100 text-gray-800 hover:bg-customGreen"
                      }`}
                    >
                      {answer}
                    </div>
                  ))}
                </div>
              )}

              {/* Display Error Message */}
              <ErrorMessage
                name={`selectedAnswers_${vals.id}`}
                component="div"
                className="text-red-500 mt-2"
              />
            </div>
          ))
        )}
        <button
          type="submit"
          disabled={isSubmitting}
          className="bg-customGreen w-full p-4 text-2xl text-white rounded"
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </div>
    </Form>
  )}
</Formik>

  </div>
</div>
    }
    


    </div>
  );
};

export default SearchableDropdown;
