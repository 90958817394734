// EventContext.js

import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader } from "../apis/header";

const EventContext = createContext();

export const EventContextProvider = ({ children }) => {
  const [allEvents, setAllEvents] = useState(null);
  const [allRetreats, setAllRetreats] = useState(null);
  const [event, setEvent] = useState(null);
  const [addEventId, setAddEventId] = useState(null)

  const getEvents = async () => {
    try {
      const headers = jsonHeader();

      const response = await ApiTemplate("get", "/api/v1/events", {}, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        setAllEvents(response["data"]);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getEventsByLocation = async (data) => {
    try {
      const headers = jsonHeader();

      const response = await ApiTemplate("post", "/api/v1/event/by/location", data, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        setAllEvents({...allEvents,"events":response["data"]});
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getEventById = async (id) => {
    try {
      const headers = jsonHeader();

      const response = await ApiTemplate("get", "/api/v1/event/"+id, {}, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        setEvent(response["data"]);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const addEvent = async (token, eventData) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data;",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("post", "/api/v1/event", eventData, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        // setAllCategories([...allCategories, response["data"]]);
        setAddEventId(response["data"]["id"])
        getEvents(token) 
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const addExtraImages = async (token, eventData) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data;",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("post", "/api/v1/event/extra/images", eventData, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        // setAllCategories([...allCategories, response["data"]]);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const addEventAccomodation = async (token, eventData) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("post", "/api/v1/event/accomodation", eventData, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        // setAllCategories([...allCategories, response["data"]]);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const addEventFeatures = async (token, eventData) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("post", "/api/v1/event/features", eventData, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        // setAllCategories([...allCategories, response["data"]]);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const addEventPlans = async (token, eventData) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("post", "/api/v1/event/plan", eventData, headers);
      if (response && response["success"] === true) {
        // console.log(response["data"]);
        // setAllCategories([...allCategories, response["data"]]);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const deleteEvent = async (token, eventId) => {
    try {
      // Implement the logic to delete an event using your API here
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const updateEvent = async (token, eventId, eventData) => {
    try {
      // Implement the logic to update an event using your API here
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getAllRetreats = async () => {
    try {
      const headers = jsonHeader();

      const response = await ApiTemplate("get", "/api/v1/all/retreats", {}, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        setAllRetreats(response["data"]);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  return (
    <EventContext.Provider
      value={{
        allEvents,
        getEvents,
        addEvent,
        addExtraImages,
        deleteEvent,
        updateEvent, getEventById, event , getEventsByLocation,
        addEventId, setAddEventId, addEventAccomodation, addEventFeatures ,addEventPlans,
        allRetreats, getAllRetreats
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const useEventContext = () => {
  const eventContextValue = useContext(EventContext);
  return eventContextValue;
};
