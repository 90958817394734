import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCREgOYEyx5epw7k1Dd-LjSX735wms4Wa0",
  authDomain: "yoga-retreat-e3104.firebaseapp.com",
  projectId: "yoga-retreat-e3104",
  storageBucket: "yoga-retreat-e3104.appspot.com",
  messagingSenderId: "218994952192",
  appId: "1:218994952192:web:913f4e040089726e3f70e5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get the authentication instance
export const auth = getAuth(app);

// Create a GoogleAuthProvider instance
const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

// Function to sign in with Google
export const signInWithGoogle = () => signInWithPopup(auth, provider);


export default app;


